import './App.css';

// Image imports
import iosDownloadBtn from './images/download_appstore_btn.png';
import iosDownloadBtn2x from './images/download_appstore_btn@2x.png';
import iosDownloadBtn3x from './images/download_appstore_btn@3x.png';
import droidDownloadBtn from './images/download_droid_btn.png';
import droidDownloadBtn2x from './images/download_droid_btn@2x.png';
import droidDownloadBtn3x from './images/download_droid_btn@3x.png';
import appPreview from './images/app_preview.png';
import appPreview2x from './images/app_preview@2x.png';
import appPreview3x from './images/app_preview@3x.png';

// Social media icons
import linkedInIcon from './images/li_icon.png';
import linkedInIcon2x from './images/li_icon@2x.png';
import linkedInIcon3x from './images/li_icon@3x.png';
import instaIcon from './images/insta_icon.png';
import instaIcon2x from './images/insta_icon@2x.png';
import instaIcon3x from './images/insta_icon@3x.png';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

const CustomForm = ({ status, message, onValidated }) => {
  const [userName, setUserName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState('');

  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');

  const onNameChg = (e: any) => setUserName(e.target.value);
  const onEmailChg = (e: any) => setUserEmail(e.target.value);

  const submit = (evt) => {
    if (userName === '') {
      setNameError(true);
      setNameHelperText('Please enter your full name');
      return;
    } else {
      setNameError(false);
      setNameHelperText('');
    }

    if (userEmail === '' || !userEmail.includes('@')) {
      setEmailError(true);
      setEmailHelperText('Please enter a valid email address');
      return;
    } else {
      setEmailError(false);
      setEmailHelperText('');
    }

    // if (process.env.REACT_APP_ENV !== "prod") {
    //   return false;
    // }

    return onValidated({
      EMAIL: userEmail,
      FULLNAME: userName,
    });
  };

  return (
    <div id='mc_form'>
      {status === 'sending' && (
        <div className='mc_status black_text'>sending...</div>
      )}
      {status === 'error' && (
        <div
          className='mc_status error-text'
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === 'success' && (
        <div
          className='mc_status black_text'
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {/* <br /> */}
      <TextField
        label='Full Name'
        variant='outlined'
        onChange={onNameChg}
        value={userName}
        helperText={nameHelperText}
        error={nameError}
        className='form_input'
      />{' '}
      <br />
      <TextField
        label='Email'
        variant='outlined'
        onChange={onEmailChg}
        value={userEmail}
        helperText={emailHelperText}
        error={emailError}
        className='form_input'
      />
      <br />
      <Button
        variant='contained'
        onClick={submit}
        style={{
          background: '#000000',
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
          borderRadius: '4px',
          color: '#ffffff',
          textTransform: 'none',
          padding: '3% 7% 3% 7%',
          margin: '5% 0 10% 0',
          fontFamily: 'Nunito Sans, sans-serif',
          fontWeight: 800,
        }}
      >
        Join the wait list
      </Button>
    </div>
  );
};

function App() {
  const url =
    'https://narchie.us5.list-manage.com/subscribe/post?u=21cd49c30dcac76b02b36a58f&amp;id=7c9c1b66ac';

  useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyCep1-Or7OKF28yljdkdLxA4KFbVzmbPhc',
      authDomain: 'dev-narchie-app.firebaseapp.com',
      projectId: 'dev-narchie-app',
      storageBucket: 'dev-narchie-app.appspot.com',
      messagingSenderId: '14403005125',
      appId: '1:14403005125:web:13edd8794e35a841351eac',
      measurementId: 'G-RSTC2E4EK8',
    };

    // Initialize Firebase & Analytics
    initializeApp(firebaseConfig);
    getAnalytics();
  }, []);

  return (
    <div className='App'>
      <div className='row-top'>
        <div className='row-top-content'>
          <p className='shrikhand logo'>Narchie</p>

          <div className='row'>
            <div className='column-tagline'>
              <p className='shrikhand'>Buy. Sell. Explore.</p>
              <p className='shrikhand column_row2'>Unique Homeware</p>

              <p className='nunito-sans narchie-intro'>
                Narchie is the social marketplace for homeware. Where decor
                enthusiasts can buy, sell and connect.
              </p>

              <div className='store-buttons'>
                <a
                  href='https://apps.apple.com/us/app/narchie/id1566835651'
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <img
                    src={iosDownloadBtn}
                    srcSet={`${iosDownloadBtn2x} 2x, ${iosDownloadBtn3x} 3x`}
                    alt='Download on the App Store'
                    className='ios-download-btn'
                  />
                </a>

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href='#'
                  rel='noreferrer'
                  id='android-form-btn'
                  onClick={() => {
                    const element = document.getElementById('android-intro-text');
                    const topPos = element.getBoundingClientRect().top + window.pageYOffset;

                    window.scrollTo({
                      top: topPos, // scroll so that the element is at the top of the view
                      behavior: 'smooth' // smooth scroll
                    });
                  }}
                >
                  <img
                    src={droidDownloadBtn}
                    srcSet={`${droidDownloadBtn2x} 2x, ${droidDownloadBtn3x} 3x`}
                    alt='Download on the App Store'
                    className='droid-download-btn'
                  />
                </a>
              </div>

              <div className='seller-interest'>
                <span><a href='https://calendly.com/jamesnarchie/seller' target={'_blank'} rel="noreferrer">Become a Narchie seller</a></span>
              </div>
            </div>

            <div className='column-app-preview'>
              <img
                src={appPreview}
                srcSet={`${appPreview2x} 2x, ${appPreview3x} 3x`}
                alt='Narchie Instagram'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row-bottom'>
        <div className='row-bottom-content'>
          <p id="android-intro-text" className='shrikhand black-text android-title'>
            Coming to Android soon!
          </p>
          <p className='nunito android-subtitle'>
            Be the first to know when we launch on GooglePlay. Register your
            interest below and we'll let you know when we've available for
            Android.
          </p>

          <>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
          </>
        </div>
      </div>

      <div className='footer'>
        <div className='footer-column-left'>
          <p>Narchie 2021 &copy;</p>
        </div>

        <div className='footer-column-right'>
          <div className='social-media-icons'>
            <a href='https://www.linkedin.com/company/narchie/' target='_blanket'>
              <img
                src={linkedInIcon}
                srcSet={`${linkedInIcon2x} 2x, ${linkedInIcon3x} 3x`}
                alt='Narchie on LinkedIn'
                className='li-icon'
              />
            </a>
            <a href='https://www.instagram.com/narchiehome/' target='_blanket'>
              <img
                src={instaIcon}
                srcSet={`${instaIcon2x} 2x, ${instaIcon3x} 3x`}
                alt='Narchie Instagram'
                className='insta-icon'
              />
            </a>
          </div>

          <div className='address'>
            <p>1-45 Durham Street, London, England, SE11 5JH</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
